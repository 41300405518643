import '@mdi/font/css/materialdesignicons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import App from './App.vue';
import vuetify from './plugins/vuetify';
// import './registerServiceWorker'; // TO-DO : Fix the Service Worker
import router from './router';
import store from './store';
import http from './utils/axios';
import 'vuetify/dist/vuetify.min.css';

Vuetify.config.silent = true;

Vue.config.productionTip = false;

Vue.prototype.$http = http;

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
});

app.$mount('#app');
