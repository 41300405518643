import axios from 'axios';
import CryptoJS from 'crypto-js';
import { encrypt, decrypt } from './crypto';
const secretKey = CryptoJS.enc.Utf8.parse('Sn3U6koD8KypZ1io2Zr+v9zi+auLLfSxfSmBMqxdN2E='); // 32-character secret key for AES-256

// https://coderethinked.com/how-to-cancel-a-request-using-axios-in-vue-js/
const http = axios.create({
  // baseURL: 'http://localhost:8080/api/v1/',

  baseURL: 'https://apisaipavaninew.digitelex.in/public/api/v1/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
http.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization =
        'Bearer ' + localStorage.getItem('token');
      config.headers.accept = 'application/json';
    }
    // if (config.data) {

    //   config.data = { encrypted: encrypt(config.data) };
    // }
    // config.timeout = 90000;
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
http.interceptors.response.use(
  (response) => {
    // Do something with response data
    if (response.data) {
      // Decrypt the response data

      // response.data = decrypt(response.data.data);
    }
    return response;
  },
  (err) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const status = err.response?.status || 500;
    // we can handle global errors here
    switch (status) {
      // authentication (token related issues)
      case 401: {
        sessionStorage.removeItem('token');
        // window.location = '/login';
        window.location.replace('/login');

        // return Promise.reject({ message: err.message, code: 409 });
        break;
      }

      // forbidden (permission related issues)
      case 403: {
        // return Promise.reject({ message: err.message, code: 409 });
        break;
      }

      // bad request
      case 400: {
        // return Promise.reject({ message: err.message, code: 400 });
        break;
      }

      // not found
      case 404: {
        // return Promise.reject({ message: err.message, code: 404 });
        break;
      }

      // conflict
      case 409: {
        // return Promise.reject({ message: err.message, code: 409 });
        break;
      }

      // unprocessable
      case 422: {
        // return Promise.reject({ message: err.message, code: 422 });
        break;
      }
      // unprocessable
      case 500: {
        // return Promise.reject({ message: err.message, code: 500 });
        break;
      }

      // generic api error (server related) unexpected
      default: {
        // return Promise.reject({ message: err.message, code: 500 });
        break;
      }
    }
    return err.response;
  },
  // function (err) {
  // Do something with response error
  // return Promise.reject(error);

  // },
);
export default http;
