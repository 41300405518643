<template>
  <router-view />
</template>

<script>
import { useIdle } from '@vueuse/core';

export default {
  name: 'App',

  components: {},

  data: () => ({
    sleepData: useIdle(5000),
  }),
  methods: {},
  watch: {
    // sleepData: {
    //   deep: true,
    //   handler: function (val) {},
    // },
  },
};
</script>
